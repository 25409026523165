import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  TableSortLabel,
  Button,
  Popover,
  Checkbox,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Card,
  CardContent,
  CardActions,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import FilterListIcon from '@mui/icons-material/FilterList';
import { collection, query, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  borderRadius: '8px',
  maxWidth: '90%',
  width: '1200px',
  maxHeight: '90vh',
  overflowY: 'auto',
  position: 'relative',
}));

const statusColors = {
  'New': '#2196F3', // Changed to blue
  'In Progress': '#FFC107',
  'Closed Won': '#4CAF50', // Changed to green
  'Not Qualified': '#F44336',
};

const owners = [
  'Nemanja Tosic',
  'Emir Alibasic',
  'Gustav Roman',
  'Arber Berisha',
];

const formatStoreName = (storeName) => {
  if (!storeName) return '';
  if (storeName === 'four-office') return 'Four Office';
  const [store, city] = storeName.split('-');
  return `Elgiganten ${city.charAt(0).toUpperCase() + city.slice(1)}`;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  padding: '8px 16px', // Reduce padding here
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledBodyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px', // Reduce padding for body cells
}));

const ownerColor = '#f0f0f0'; // Light gray color for owner background

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  color: 'white !important',
  '&:hover': {
    color: 'white !important',
  },
  '&.Mui-active': {
    color: 'white !important',
  },
  '& .MuiTableSortLabel-icon': {
    color: 'white !important',
  },
}));

const StyledSearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

function AdminModal({ open, onClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLead, setSelectedLead] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [orderBy, setOrderBy] = useState('companyName');
  const [order, setOrder] = useState('asc');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [statusFilters, setStatusFilters] = useState(Object.keys(statusColors).reduce((acc, status) => ({ ...acc, [status]: false }), {}));
  const [ownerFilters, setOwnerFilters] = useState(owners.reduce((acc, owner) => ({ ...acc, [owner]: false }), {}));
  const [storeFilters, setStoreFilters] = useState({});
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (open) {
      fetchLeads();
    }
  }, [open]);

  useEffect(() => {
    const filtered = leads.filter(lead =>
      Object.values(lead).some(value =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) &&
      (Object.values(statusFilters).every(v => !v) || statusFilters[lead.status]) &&
      (Object.values(ownerFilters).every(v => !v) || ownerFilters[lead.owner]) &&
      (Object.values(storeFilters).every(v => !v) || storeFilters[lead.store]) &&
      lead.isArchived === (currentTab === 1)
    );
    setFilteredLeads(filtered);
  }, [searchTerm, leads, statusFilters, ownerFilters, storeFilters, currentTab]);

  const fetchLeads = async () => {
    const leadsCollection = collection(db, 'leads');
    const leadsSnapshot = await getDocs(leadsCollection);
    const leadsList = leadsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), isArchived: doc.data().isArchived || false }));
    setLeads(leadsList);
    setFilteredLeads(leadsList);

    // Initialize store filters
    const stores = [...new Set(leadsList.map(lead => lead.store))];
    setStoreFilters(stores.reduce((acc, store) => ({ ...acc, [store]: false }), {}));
  };

  const handleDeleteLead = async (id) => {
    if (window.confirm('Are you sure you want to delete this lead?')) {
      await deleteDoc(doc(db, 'leads', id));
      fetchLeads();
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    await updateDoc(doc(db, 'leads', id), { status: newStatus });
    fetchLeads();
  };

  const handleLeadClick = (lead) => {
    setSelectedLead(lead);
    setDetailsDialogOpen(true);
  };

  const handleCloseDetailsDialog = () => {
    setDetailsDialogOpen(false);
  };

  const handleOwnerChange = async (id, newOwner) => {
    // Om "Välj säljare" är valt, spara det som en tom sträng i databasen
    const ownerValue = newOwner === "Välj säljare" ? "" : newOwner;
    await updateDoc(doc(db, 'leads', id), { owner: ownerValue });
    fetchLeads();
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedLeads = React.useMemo(() => {
    const comparator = (a, b) => {
      if (b[orderBy] < a[orderBy]) {
        return order === 'asc' ? 1 : -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    };

    return [...filteredLeads].sort(comparator);
  }, [filteredLeads, order, orderBy]);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterChange = (filterType, value) => {
    switch (filterType) {
      case 'status':
        setStatusFilters(prev => ({ ...prev, [value]: !prev[value] }));
        break;
      case 'owner':
        setOwnerFilters(prev => ({ ...prev, [value]: !prev[value] }));
        break;
      case 'store':
        setStoreFilters(prev => ({ ...prev, [value]: !prev[value] }));
        break;
      default:
        break;
    }
  };

  const handleStatusClick = (event) => {
    event.stopPropagation();
  };

  const handleOwnerClick = (event) => {
    event.stopPropagation();
  };

  const handleArchiveLead = async (id, isArchived) => {
    await updateDoc(doc(db, 'leads', id), { isArchived: !isArchived });
    fetchLeads();
  };

  const MobileLeadCard = ({ lead }) => (
    <Card sx={{ mb: 2, backgroundColor: 'white', position: 'relative' }}>
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{lead.companyName}</Typography>
        <Typography><strong>Säljare:</strong> {lead.seller}</Typography>
        <Typography><strong>Butik:</strong> {formatStoreName(lead.store)}</Typography>
        <Box mt={1}>
          <FormControl fullWidth size="small">
            <Select
              value={lead.status || 'New'}
              onChange={(e) => handleStatusChange(lead.id, e.target.value)}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: statusColors[selected],
                    color: 'white',
                    fontWeight: 'normal',
                    fontSize: '0.875rem',
                    padding: '2px 8px',
                    borderRadius: '16px',
                    width: '100%',
                  }}
                >
                  {selected}
                  <ArrowDropDownIcon />
                </Box>
              )}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-icon': {
                  display: 'none',
                },
              }}
            >
              {Object.keys(statusColors).map((status) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mt={1}>
          <FormControl fullWidth size="small">
            <Select
              value={lead.owner || "Välj säljare"}
              onChange={(e) => handleOwnerChange(lead.id, e.target.value)}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: ownerColor,
                    color: 'black',
                    fontWeight: 'normal',
                    fontSize: '0.875rem',
                    padding: '2px 8px',
                    borderRadius: '16px',
                    width: '100%',
                    minHeight: '24px',
                  }}
                >
                  {selected}
                  <ArrowDropDownIcon />
                </Box>
              )}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-icon': {
                  display: 'none',
                },
              }}
            >
              <MenuItem value="Välj säljare">Välj säljare</MenuItem>
              {owners.map((owner) => (
                <MenuItem key={owner} value={owner}>{owner}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button size="small" onClick={() => handleLeadClick(lead)}>Visa detaljer</Button>
        <Box>
          <IconButton 
            size="small" 
            onClick={(e) => { 
              e.stopPropagation(); 
              handleArchiveLead(lead.id, lead.isArchived); 
            }}
            sx={{ mr: 1 }}
          >
            {lead.isArchived ? <UnarchiveIcon fontSize="small" /> : <ArchiveIcon fontSize="small" />}
          </IconButton>
          <IconButton 
            size="small" 
            color="error" 
            onClick={() => handleDeleteLead(lead.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </CardActions>
    </Card>
  );

  return (
    <StyledModal open={open} onClose={onClose}>
      <StyledPaper>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" component="h2" gutterBottom>
          Admin Panel - Leads
        </Typography>

        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab label="Aktiva" />
          <Tab label="Arkiverade" />
        </Tabs>

        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <StyledSearchField
            placeholder="Search leads..."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flexGrow: 1, mr: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={handleFilterClick}
            sx={{
              color: 'black',
              borderColor: 'black',
              '&:hover': {
                borderColor: 'black',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            Filter
          </Button>
        </Box>
        <Popover
          open={Boolean(filterAnchorEl)}
          anchorEl={filterAnchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box p={2}>
            <Typography variant="h6" gutterBottom>Status</Typography>
            <FormGroup>
              {Object.keys(statusColors).map((status) => (
                <FormControlLabel
                  key={status}
                  control={<Checkbox checked={statusFilters[status]} onChange={() => handleFilterChange('status', status)} />}
                  label={status}
                />
              ))}
            </FormGroup>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Ägare</Typography>
            <FormGroup>
              {owners.map((owner) => (
                <FormControlLabel
                  key={owner}
                  control={<Checkbox checked={ownerFilters[owner]} onChange={() => handleFilterChange('owner', owner)} />}
                  label={owner}
                />
              ))}
            </FormGroup>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Butik</Typography>
            <FormGroup>
              {Object.keys(storeFilters).map((store) => (
                <FormControlLabel
                  key={store}
                  control={<Checkbox checked={storeFilters[store]} onChange={() => handleFilterChange('store', store)} />}
                  label={formatStoreName(store)}
                />
              ))}
            </FormGroup>
          </Box>
        </Popover>
        {isMobile ? (
          <Box mt={2}>
            {sortedLeads.map((lead) => (
              <MobileLeadCard key={lead.id} lead={lead} />
            ))}
          </Box>
        ) : (
          <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <StyledTableSortLabel
                      active={orderBy === 'companyName'}
                      direction={orderBy === 'companyName' ? order : 'asc'}
                      onClick={() => handleRequestSort('companyName')}
                    >
                      Kundnamn
                    </StyledTableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTableSortLabel
                      active={orderBy === 'seller'}
                      direction={orderBy === 'seller' ? order : 'asc'}
                      onClick={() => handleRequestSort('seller')}
                    >
                      Säljare
                    </StyledTableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTableSortLabel
                      active={orderBy === 'status'}
                      direction={orderBy === 'status' ? order : 'asc'}
                      onClick={() => handleRequestSort('status')}
                    >
                      Status
                    </StyledTableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTableSortLabel
                      active={orderBy === 'owner'}
                      direction={orderBy === 'owner' ? order : 'asc'}
                      onClick={() => handleRequestSort('owner')}
                    >
                      Ägare
                    </StyledTableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTableSortLabel
                      active={orderBy === 'store'}
                      direction={orderBy === 'store' ? order : 'asc'}
                      onClick={() => handleRequestSort('store')}
                    >
                      Butik
                    </StyledTableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedLeads.map((lead) => (
                  <StyledTableRow key={lead.id} onClick={() => handleLeadClick(lead)}>
                    <StyledBodyTableCell>{lead.companyName}</StyledBodyTableCell>
                    <StyledBodyTableCell>{lead.seller}</StyledBodyTableCell>
                    <StyledBodyTableCell onClick={handleStatusClick}>
                      <FormControl fullWidth size="small">
                        <Select
                          value={lead.status || 'New'}
                          onChange={(e) => handleStatusChange(lead.id, e.target.value)}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                backgroundColor: statusColors[selected],
                                color: 'white',
                                fontWeight: 'normal',
                                fontSize: '0.875rem',
                                padding: '2px 8px',
                                borderRadius: '16px',
                                width: '100%',
                              }}
                            >
                              {selected}
                              <ArrowDropDownIcon />
                            </Box>
                          )}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiSelect-icon': {
                              display: 'none',
                            },
                          }}
                        >
                          {Object.keys(statusColors).map((status) => (
                            <MenuItem key={status} value={status}>{status}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell onClick={handleOwnerClick}>
                      <FormControl fullWidth size="small">
                        <Select
                          value={lead.owner || "Välj säljare"}
                          onChange={(e) => handleOwnerChange(lead.id, e.target.value)}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                backgroundColor: ownerColor,
                                color: 'black',
                                fontWeight: 'normal',
                                fontSize: '0.875rem',
                                padding: '2px 8px',
                                borderRadius: '16px',
                                width: '100%',
                                minHeight: '24px',
                              }}
                            >
                              {selected}
                              <ArrowDropDownIcon />
                            </Box>
                          )}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiSelect-icon': {
                              display: 'none',
                            },
                          }}
                        >
                          <MenuItem value="Välj säljare">Välj säljare</MenuItem>
                          {owners.map((owner) => (
                            <MenuItem key={owner} value={owner}>{owner}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>{formatStoreName(lead.store)}</StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <IconButton 
                        size="small" 
                        onClick={(e) => { 
                          e.stopPropagation(); 
                          handleArchiveLead(lead.id, lead.isArchived); 
                        }}
                        sx={{ mr: 1 }}
                      >
                        {lead.isArchived ? <UnarchiveIcon fontSize="small" /> : <ArchiveIcon fontSize="small" />}
                      </IconButton>
                      <IconButton 
                        size="small" 
                        onClick={(e) => { 
                          e.stopPropagation(); 
                          handleDeleteLead(lead.id); 
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </StyledBodyTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog open={detailsDialogOpen} onClose={handleCloseDetailsDialog} maxWidth="md" fullWidth>
          <DialogTitle>
            Leadinformation
            <IconButton
              aria-label="close"
              onClick={handleCloseDetailsDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {selectedLead && (
              <Box>
                <Typography><strong>Företagsnamn:</strong> {selectedLead.companyName}</Typography>
                <Typography><strong>Kontaktperson:</strong> {selectedLead.contactName}</Typography>
                <Typography><strong>E-post:</strong> {selectedLead.contactEmail}</Typography>
                <Typography><strong>Telefon:</strong> {selectedLead.contactPhone}</Typography>
                <Typography><strong>Lead-information:</strong> {selectedLead.leadInfo}</Typography>
                <Typography><strong>Butik:</strong> {formatStoreName(selectedLead.store)}</Typography>
                <Typography><strong>Säljare:</strong> {selectedLead.seller}</Typography>
                <Typography><strong>Status:</strong> {selectedLead.status}</Typography>
                <Typography><strong>Ägare:</strong> {selectedLead.owner || 'Ej tilldelad'}</Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDetailsDialog}>Stäng</Button>
          </DialogActions>
        </Dialog>
      </StyledPaper>
    </StyledModal>
  );
}

export default AdminModal;